// Your variable overrides
$primary: #126072;
$info: $primary;
$font-family-base: 'Poppins', sans-serif;

// Required
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

// Optional
@import "node_modules/bootstrap/scss/reboot";
@import "node_modules/bootstrap/scss/type";
@import "node_modules/bootstrap/scss/grid";
@import "node_modules/bootstrap/scss/alert";
@import "node_modules/bootstrap/scss/forms";
@import "node_modules/bootstrap/scss/buttons";
@import "node_modules/bootstrap/scss/dropdown";
@import "node_modules/bootstrap/scss/modal";
@import "node_modules/bootstrap/scss/close";
@import "node_modules/bootstrap/scss/popover";
@import "node_modules/bootstrap/scss/progress";
@import "node_modules/bootstrap/scss/tables";
@import "node_modules/bootstrap/scss/utilities";
@import "node_modules/bootstrap/scss/input-group";
@import "node_modules/bootstrap/scss/custom-forms";

@import url('https://fonts.googleapis.com/css?family=Poppins:400,700&display=swap');


.app-logo {
  font-weight: 700;
  color: $primary
}

.app-logo::after {
  content: 'BETA';
  font-size: 50%;
  margin-left: .5em;
}

.custom-file-label::after {
  content: 'Sfoglia' !important;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.app-footer {
  margin-top: auto;
}

select.form-control.is-invalid {
     background-position: right calc(0.375em + 0.1875rem + 6px) center;
}

.text-dotted {
    border-bottom: 1px dotted $primary;
}
